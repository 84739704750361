.eventsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    flex-basis: 100%;
}

.event {
    background-color: var(--background);
    border: 2px solid var(--foreground);
    border-radius: 10px;
    width: 22.5vw;
    height: 33vw;
    display: flex;
    overflow: hidden;
    /* padding: 10px; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    box-shadow: var(--background-colour) 0px 0px 25px 0px;

    -moz-transition: border 0.35s ease-out;
    -o-transition: border 0.35s ease-out;
    -webkit-transition: border 0.35s ease-out;
    transition: border 0.35s ease-out;

    -moz-transition: box-shadow 0.3s ease-out;
    -o-transition: box-shadow 0.3s ease-out;
    -webkit-transition: box-shadow 0.3s ease-out;
    transition: box-shadow 0.3s ease-out;
}

.event .imageContainer {
    height: inherit;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 100%;
}

.event .imageCover {
    position: absolute;
    background-color: #000000bb;
    width: inherit;
    height: inherit;

    -moz-transition: background-color 0.35s ease-out;
    -o-transition: background-color 0.35s ease-out;
    -webkit-transition: background-color 0.35s ease-out;
    transition: background-color 0.35s ease-out;
}

.event img {
    width: 25vw;
    flex-basis: 100%;
    border-radius: 10px;
}

.event .info {
    color: white;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: flex-end;
    bottom: 42.5%;
    gap: 10px;
    text-align: center;
    /* background-color: #000000bb; */
    width: inherit;
    /* height: inherit; */
    height: 10vw;

    -moz-transition: bottom 0.35s ease-out;
    -o-transition: bottom 0.35s ease-out;
    -webkit-transition: bottom 0.35s ease-out;
    transition: bottom 0.35s ease-out;
}

.eventName {
    font-size: calc(15px + 1vw);
    font-weight: 500;
    /* flex-basis: 100%; */
    padding-left: 10px;
    padding-right: 10px;
    text-shadow: #000000 1px 1px 10px;
}

.eventDate {
    font-size: calc(10px + 1vw);
    font-weight: 200;
    flex-basis: 100%;
    padding-left: 10px;
    padding-right: 10px;
    text-shadow: #000000 1px 1px 20px;
}

#eventTitle {
    font-weight: 400;
    font-size: 3.5vw;
    padding: 25px 10px 25px 10px;
    text-align: center;
}

@media screen and (min-width: 701px) {
    /* desktop */

    .event:hover {
        border: 2px solid var(--accent);
        box-shadow: var(--accent) 0px 0px 25px 0px;
        z-index: 0;
    }

    .event:hover .imageCover {
        background-color: #00000000;
    }

    .event:hover .info {
        bottom: 4%;
    }
}

@media screen and (max-width: 700px) {
    /* mobile */

    .eventsList {
        padding-bottom: 30px;
    }

    .event {
        width: 85vw;
        height: 50vh;
    }

    .event .info {
        background-color: #00000050;
    }

    .eventName {
        font-size: calc(10px + 5vw);
        font-weight: 500;
    }
    .eventDate {
        font-size: calc(10px + 3vw);
        font-weight: 400;
    }

    #eventTitle {
        font-size: 8.5vw;
    }
}
