#galleryCategoryTitle {
    font-weight: 400;
    font-size: 3.5vw;
    padding: 25px 50px 25px 50px;
    color: var(--second-foreground);
    text-decoration: none;
}

@media screen and (max-width: 700px) {
    #galleryCategoryTitle {
        font-size: 15vw;
    }
}

#eventTitle {
    /* text-decoration-line: none; */
    color: var(--second-foreground);
    text-decoration-color: var(--accent);
    /* text-decoration: none; */
}

#eventTitle:hover {
    text-decoration-color: #00000000;
}

@media screen and (max-width: 700px) {
    #eventTitle {
        font-size: 15vw;
    }
}

.gridRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: 95%;
    max-width: 1920px;
}

.gridColumn {
    flex: 10%;
    padding: 0 4px;
}

.gridColumn img {
    margin-bottom: 8px;
    vertical-align: middle;
    width: 100%;
}

.scrollableElement {
    /* pointer-events: none; */
    padding: 0 0;
    position: relative;
    max-width: 100%;
    margin: 0rem auto;
    background-color: none;
    color: none;
}

.back-to-top-link {
    position: fixed;
    position: sticky;
    pointer-events: all;
    top: calc(100vh - 5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--second-foreground);
    border: 2px solid var(--second-foreground);
}

.back-to-top-link svg {
    color: var(--second-background);
}

.back-to-top-link:hover {
    background-color: var(--second-background);
}
.back-to-top-link:hover svg {
    color: var(--second-foreground);
}

.back-to-top-wrapper {
    position: absolute;
    top: 100vh;
    right: 1vw;
    bottom: 0;
    width: 3em;
}
