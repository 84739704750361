#contactMain {
    display: block;
    text-align: center;
}

#contactTitle {
    font-weight: 400;
    font-size: 5vw;
}

#contactBox {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    text-align: center;
}

.contactLink {
    flex-basis: 100%;
}

@media screen and (max-width: 700px) {
    #contactTitle {
        font-size: calc(15px + 10vw);
    }

    .contactLink {
        font-size: calc(15px + 10vw);
    }
}

.contactIcon {
    color: #4747c5;
}

.socialIcon {
    min-width: 100px;
    min-height: 100px;
}
