#aboutMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

#aboutTitle {
    flex-basis: 100%;
    font-weight: 400;
    font-size: 3.5vw;
    padding: 25px 50px 25px 50px;
}

#aboutDescription {
    font-weight: 400;
    font-size: calc(5px + 1.5vw);
    flex-basis: 100%;
    margin-bottom: 150px;
}

#aboutFooter {
    width: 50%;
}

@media screen and (max-width: 700px) {
    #aboutTitle {
        font-size: calc(15px + 10vw);
    }

    #aboutDescription {
        font-size: calc(15px + 3.5vw);
    }
    
    #aboutFooter {
        width: 85%;
    }
}