.projectsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5vw;
    flex-basis: 100%;
}

.project {
    background-color: var(--background);
    border: 2px solid var(--foreground);
    border-radius: 10px;
    width: 20vw;
    display: flex;
    overflow: hidden;
    padding: 1.5vw;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    text-decoration: none;

    box-shadow: var(--background-colour) 0px 0px 25px 0px;

    -moz-transition: border 0.35s ease-out;
    -o-transition: border 0.35s ease-out;
    -webkit-transition: border 0.35s ease-out;
    transition: border 0.35s ease-out;

    -moz-transition: box-shadow 0.3s ease-out;
    -o-transition: box-shadow 0.3s ease-out;
    -webkit-transition: box-shadow 0.3s ease-out;
    transition: box-shadow 0.3s ease-out;
}


.project img {
    width: 100%;
    flex-basis: 100%;
    /* border-radius: 10px; */
    /* border: 1px var(--accent) solid; */
    margin-bottom: 1vw;
}

.project .info {
    color: white;
    /* position: absolute; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: flex-end;
    text-align: center;
    width: inherit;

    -moz-transition: bottom 0.35s ease-out;
    -o-transition: bottom 0.35s ease-out;
    -webkit-transition: bottom 0.35s ease-out;
    transition: bottom 0.35s ease-out;
}

.projectName {
    font-size: calc(10px + 1vw);
    font-weight: 500;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.projectClient {
    font-size: calc(5px + 1vw);
    font-weight: 400;
    flex-basis: 100%;
    padding-left: 5px;
    padding-right: 5px;
}

@media screen and (min-width: 701px) {
    /* desktop */

    .project:hover {
        border: 2px solid var(--accent);
        box-shadow: var(--accent) 0px 0px 25px 0px;
        z-index: 0;
    }

    .project:hover .imageCover {
        background-color: #00000000;
    }

    .project:hover .info {
        bottom: 4%;
    }
}

@media screen and (max-width: 700px) {
    /* mobile */

    .projectsList {
        padding-bottom: 30px;
    }

    .project {
        width: 85vw;
        /* height: 50vh; */
        padding: 3vw;
    }

    .project .info {
        background-color: #00000050;
    }

    .projectName {
        font-size: calc(5px + 5vw);
        font-weight: 500;
    }

    .projectDate {
        font-size: calc(10px + 3vw);
        font-weight: 400;
    }

    #projectTitle {
        font-size: 8.5vw;
    }

    .projectClient {
        font-size: calc(15px + 1vw);
        font-weight: 400;
        flex-basis: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }
}